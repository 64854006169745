import React from "react";
import PropTypes from "prop-types";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";
import { Link } from "gatsby";

const ProductCard = ({ image, title, text, link }) => (
  <div
    className="card"
    style={{
      minHeight: "37em",
      borderRadius: "4px",
      border: "1px solid #e8e8e8",
      overflow: "hidden",
    }}
  >
    <div className="" style={{ maxHeight: "20em" }}>
      <PreviewCompatibleImage image={image} />
    </div>
    <div className="card-content" style={{ minHeight: "20em" }}>
      <div className="media">
        <div className="media-left">
          <div className="media-content">
            <p className="title is-4">{title}</p>
          </div>
        </div>
      </div>
      <p
        style={{
          minHeight: "6em",
        }}
      >
        {text}
      </p>
      <div style={{ position: "absolute", bottom: "2em", left: "50%" }}>
        <div style={{ position: "relative", left: "-50%" }}>
          <Link className="btn" to={link}>
            Läs om {title}
          </Link>
        </div>
      </div>
    </div>
  </div>
);

ProductCard.propTypes = {
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  text: PropTypes.string,
  link: PropTypes.string,
};

export default ProductCard;
