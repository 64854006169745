import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const PreviewCompatibleImage = ({ alt = "", image }) => {
  const imageElem = getImage(image);

  return <GatsbyImage image={imageElem} alt={alt} />;
};

PreviewCompatibleImage.propTypes = {
  alt: PropTypes.string,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
};

export default PreviewCompatibleImage;
